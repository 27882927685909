import * as aboutStyle from "./about.module.scss"
import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Helmet from 'react-helmet';

export default function About({ data }) {
  const title = 'Shopping list page for Cider with evans';
  return (
    <Layout pageTitle="Shopping list for Cider With Evans">
      <Helmet>
                <title>{title}</title>
                <meta name="description" content={title}/>
      </Helmet>
      <div className={aboutStyle.content}>
        <MDXRenderer >
          {data.mdx.body}
        </MDXRenderer>
      </div>

    </Layout>
  )
}

export const query2 = graphql`
  query  {
    mdx (frontmatter: {page_type: {eq: "shopping_list"}} ){
      body
    }    
  }
  `
